import {
	ArrowBack as ArrowBackIcon,
	ArrowForward as ArrowForwardIcon,
	DoubleArrow as DoubleArrowIcon,
	InsertLinkRounded as InsertLinkIcon,
} from '@mui/icons-material';
import {
	Box,
	Chip,
	Divider,
	ListItem,
	ListItemAvatar,
	ListItemIcon,
	ListItemText,
	Stack,
	Tooltip,
	Typography,
} from '@mui/material';
import React from 'react';
import Loading from 'src/components/loading';
import { auth } from '../../../../../firebase/client';
import { Message, Room } from '../../../../../firebase/firestore';
import safeDateFns, { safeFormatInTimeZone } from '../../../../../helpers/safeFormat';
import { transformFirebaseDate } from '../../utils';
import BadgedAvatar from './avatar';
import EditBar, { EditToolBar } from './editBar';
import MessageComp from './message';

interface MessageItemProps {
	room: Room,
	message: Message,
	index: number,
	messages: Message[],
	clientView: boolean,
	user: any | null,
	staff: any | null,
	clientsAtom: Record<string, any>,
	staffsAtom: Record<string, any>,
	editId: string,
	setEditId: ( id: string ) => void,
	value: string,
	setValue: ( value: string ) => void,
	hasMore: boolean,
	updateMessage: ( id: string, data: any ) => Promise<void>
}

export default function MessageItem( {
	room,
	message,
	index,
	messages,
	clientView,
	user,
	staff,
	clientsAtom,
	staffsAtom,
	editId,
	setEditId,
	value,
	setValue,
	hasMore,
	updateMessage,
}: MessageItemProps ) {
	
	let messageHolder: any;
	const messageStaff = message.from === 'AI'
		? { id: 'ai', firstName: 'AI', image: '/images/invoiss-ai.png' }
		: staffsAtom[ message.from ];
	const messageClient = clientsAtom[ message.from ];
	
	if ( messageStaff ) {
		messageHolder = {
			id       : messageStaff?.id,
			firstName: messageStaff?.firstName,
			lastName : messageStaff?.lastName,
			image    : messageStaff?.image,
		};
	} else {
		messageHolder = {
			id       : messageClient?.id,
			firstName: ( messageClient?.name || messageClient?.contact )?.split( ' ' )?.[ 0 ]?.[ 0 ],
			lastName : ( messageClient?.name || messageClient?.contact )?.split( ' ' )?.[ 1 ]?.[ 0 ],
			image    : messageClient?.image,
		};
	}
	const isMyMessage = clientView
		? user?.id === messageHolder.id
		: staff?.id === messageHolder.id;
	const isMyLastMessage = isMyMessage && messages?.[ index + 1 ]?.from !== message.from;
	
	const isPendingMessage = !message.createdAt;
	const messageDate = transformFirebaseDate( message.createdAt );
	const updatedAtDate = transformFirebaseDate( message.updatedAt );
	
	const sentFiveMinutesBeforePrevious = isPendingMessage ? true : safeDateFns.differenceInMilliseconds(
		messageDate,
		transformFirebaseDate( messages[ index - 1 ]?.createdAt ),
	) < 300000;
	const sentBySameUser = messages[ index - 1 ]?.from === messages[ index ]?.from;
	
	const skipAvatar = index === 0 && hasMore
		|| ( index !== 0 && sentFiveMinutesBeforePrevious && sentBySameUser || !messageHolder );
	const messageIsBeingEdited = editId === message.id;
	const messageOlderThanFifteen = safeDateFns.differenceInMilliseconds( new Date(), messageDate ) > 900000;
	
	return (
		<Box>
			{index === 0 && hasMore ? <Loading/> : undefined}
			{!isPendingMessage
				&& ( index === 0 || !safeDateFns.isSameDay( transformFirebaseDate( messages[ index - 1 ]?.createdAt ), messageDate ) )
				&& (
					<Divider sx={{ my: 1 }}>
						<Chip
							variant='alpha'
							color='info'
							label={safeFormatInTimeZone( messageDate, 'PPp' )}
						/>
					</Divider>
				)}
			{messageHolder?.id ? (
				<ListItem
					sx={{
						'position'           : 'relative',
						':hover'             : !messageIsBeingEdited ? {
							borderRadius: 1,
							bgcolor     : 'divider',
							transition  : '.2s',
						} : undefined,
						':not(:hover)'       : { '.editBar': { opacity: 0 }, '.time': { opacity: 0 } },
						'.editBar'           : { position: 'absolute', top: -20, right: 10 },
						'.time'              : { color: 'text.secondary' },
						'.MuiIconButton-root': { m: .5 },
						'bgcolor'            : 'transparent',
					}}>
					<ListItemAvatar
						sx={{
							minWidth : 40,
							alignSelf: 'start',
							textAlign: 'center',
						}}>
						{skipAvatar ? (
							<Tooltip title={safeFormatInTimeZone( messageDate, 'Pp' )}>
								<Typography className='time' variant='caption'>
									{safeFormatInTimeZone( messageDate, 'h:mm' )}
								</Typography>
							</Tooltip>
						) : (
							<BadgedAvatar user={messageHolder}/>
						)}
					</ListItemAvatar>
					{editId === message.id ? (
						<EditBar
							updatedAt={updatedAtDate}
							value={value}
							setEditId={setEditId}
							setValue={setValue}
							onSave={async () => {
								const data = {
									uid    : auth.currentUser!.uid,
									from   : messageStaff!.email,
									content: value.trimEnd(),
								};
								await updateMessage( editId, data );
								setValue( '' );
								setEditId( '' );
							}}
						/>
					) : (
						<MessageComp
							room={room}
							clientView={clientView}
							message={message}
							showName={!skipAvatar}
							sender={messageHolder}
							createdAtTimestamp={messageDate}
							updatedAtTimestamp={updatedAtDate}
						/>
					)}
					{!messageIsBeingEdited && isMyMessage && !messageOlderThanFifteen && (
						<EditToolBar
							roomId={room.id}
							editId={editId}
							message={message}
							setValue={setValue}
							setEditId={setEditId}
						/>
					)}
				</ListItem>
			) : (
				<ListItem>
					<ListItemIcon sx={{ minWidth: 40 }}>
						{SystemIcon( message.content, messageHolder?.id )}
					</ListItemIcon>
					<ListItemText
						primary={(
							<Stack direction='row' alignItems='center' spacing={1}>
								<Typography>
									{message.deleted ? 'Message deleted.' : message.content}
								</Typography>
								<Tooltip title={safeFormatInTimeZone( messageDate, 'Pp' )}>
									<Typography sx={{ color: 'text.secondary' }}>
										{safeFormatInTimeZone( messageDate, 'h:m a' )}
									</Typography>
								</Tooltip>
							</Stack>
						)}
					/>
				</ListItem>
			)}
			{isMyLastMessage && (
				<Typography sx={{ color: 'text.secondary', pl: '65px' }}>
					{message?.readBy.length > 1 ? 'Seen' : 'Delivered'}
				</Typography>
			)}
		</Box>
	);
};

function SystemIcon( content: string, userId?: string ) {
	if ( userId ) return;
	if ( content.includes( 'http' ) ) {
		return <InsertLinkIcon/>;
	} else if ( content.includes( 'joined' ) || content.includes( 'invited' ) ) {
		return <ArrowForwardIcon color='success'/>;
	} else if ( content.includes( 'left' ) || content.includes( 'removed' ) ) {
		return <ArrowBackIcon color='error'/>;
	} else if ( content.includes( 'created' ) ) {
		return <DoubleArrowIcon color='primary'/>;
	}
}
