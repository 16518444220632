import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, Paper, Stack, TextField, Tooltip } from '@mui/material';
import type { SetStateAction } from 'react';
import { auth } from '../../../../../firebase/client';
import { Message, useUpdateMessage } from '../../../../../firebase/firestore';
import safeDateFns from '../../../../../helpers/safeFormat';
import useConfirmDialog from '../../../../../hooks/useConfirmDialog';

export default function EditBar( {
	value,
	setValue,
	setEditId,
	updatedAt,
	onSave,
}: {
	value?: string,
	setValue?: ( value: SetStateAction<string> ) => void,
	setEditId?: ( value: SetStateAction<string> ) => void,
	updatedAt: Date,
	onSave?: () => void
} ) {
	return (
		<Box sx={{ width: '100%', ml: 1 }}>
			<Paper sx={{ p: 1, bgcolor: 'background.default' }}>
				<Grid container spacing={1} sx={{ maxHeight: 500, alignItems: 'center' }}>
					<Grid item xs={12}>
						<TextField
							fullWidth
							multiline
							value={value}
							helperText={`Sent ${safeDateFns.formatDistanceToNow( updatedAt )} ago`}
							onChange={( e ) => setValue( e.target.value )}
							onKeyDown={async ( e ) => {
								if ( e.key === 'Enter' && !e.shiftKey ) {
									e.preventDefault();
									onSave && onSave();
								} else if ( e.shiftKey && e.key === 'Enter' ) {
									setValue( value + '\n' );
									e.preventDefault();
								}
							}}
						/>
					</Grid>
					<Grid item container xs={12} justifyContent='space-between' alignItems='center'>
						<Stack direction='row' alignItems='center' spacing={1}>
							<Button variant='contained' color='alpha' onClick={() => setEditId( '' )}>
								Cancel
							</Button>
							<AsyncLoadingButton
								color='success'
								variant='contained'
								disabled={!value}
								onClick={async () => {
									onSave && onSave();
								}}>
								Save Changes
							</AsyncLoadingButton>
						</Stack>
					</Grid>
				</Grid>
			</Paper>
		</Box>
	);
}

export function EditToolBar( {
	roomId,
	editId,
	setEditId,
	setValue,
	message,
}: {
	roomId: string,
	message: Message,
	editId: string,
	setValue?: ( value: SetStateAction<string> ) => void,
	setEditId?: ( value: SetStateAction<string> ) => void
	
} ) {
	const { updateMessage } = useUpdateMessage( roomId );
	const confirmDialog = useConfirmDialog();
	
	if ( safeDateFns.differenceInMilliseconds( new Date(), message.createdAt ) > 300000 ) return null;
	
	return (
		<Stack component={Paper} className='editBar' spacing={1} direction='row' alignItems='center'>
			<Tooltip title='Edit this message'>
				<IconButton
					onClick={() => {
						message.id === editId ? setEditId( '' ) : setEditId( message.id );
						setValue( message.content );
					}}>
					<EditIcon fontSize='small'/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Delete Message'>
				<IconButton
					aria-label='delete'
					onClick={async () => {
						const value = await confirmDialog( {
							title  : 'Delete',
							message: 'Are you sure you want to delete?',
						} );
						if ( !value ) return;
						const data = {
							uid    : auth.currentUser!.uid,
							from   : message.from,
							content: message.content,
							id     : message.id,
							deleted: true,
						};
						await updateMessage( message.id, data );
					}}>
					<DeleteIcon fontSize='small'/>
				</IconButton>
			</Tooltip>
		</Stack>
	);
}
