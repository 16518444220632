import { useCompanyId } from '@/providers/auth/useSessionStorageCompanyId';
import { useEffect } from 'react';
import { useRooms } from '../../../firebase/firestore';
import { RoomType } from '../../../types/chatRoom';
import { useClients, useCommerces, useStaffs } from './utils';

export function useGraphQLChatUsers( { clientView = false } ) {
	const { sessionCompanyId } = useCompanyId();
	const { triggerClientFetch } = useClients( { clientView } );
	const { triggerStaffFetch } = useStaffs( { clientView } );
	const { triggerCommercesFetch } = useCommerces();
	const { rooms: externalRooms } = useRooms( sessionCompanyId, RoomType.EXTERNAL, clientView );
	const roomCount = externalRooms?.length;
	
	useEffect( () => {
		if ( !clientView ) {
			triggerStaffFetch( [], false );
		}
	}, [] );
	
	useEffect( () => {
		const promises = [];
		const commerceIds = externalRooms?.filter( ( room ) => room.client && room.commerceId !== null )
			.map( ( room ) => room.commerceId ) ?? [];
		const clientEmails = externalRooms?.filter( ( room ) => room.client ).map( ( room ) => room.client ) ?? [];
		const staffCriteria = externalRooms?.flatMap( ( room ) => room.staffs?.map( ( staff ) => ( {
			email    : staff,
			companyId: room.company,
		} ) ) ?? [] ).filter( ( { email } ) => email );
		
		if ( !clientView && clientEmails.length > 0 ) {
			promises.push( triggerClientFetch( clientEmails ) );
		}
		
		if ( clientView && staffCriteria?.length > 0 ) {
			promises.push( triggerStaffFetch( staffCriteria, true ) );
		}
		
		if ( commerceIds.length > 0 ) {
			promises.push( triggerCommercesFetch( commerceIds ) );
		}
		
		if ( promises.length > 0 ) {
			Promise.all( promises ).then( () => {} )
				.catch( ( error ) => {
					console.error( 'Error with fetch operations:', error );
				} );
		}
	}, [ roomCount, clientView, sessionCompanyId ] );
}
