import Attachment from '@/components/fileUploading/attachment';
import PageLinkComponent from '@/components/page/linkComponent';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import Markdown from 'markdown-to-jsx';
import { Message, Room } from '../../../../../firebase/firestore';
import safeDateFns, { safeFormatInTimeZone } from '../../../../../helpers/safeFormat';

export type SenderType = {
	id?: string,
	firstName?: string,
	lastName?: string,
	image?: string
};

const validURL = ( str ) => {
	// This pattern is used to strictly check the string if it's a URL.
	const pattern = new RegExp( '^https?:\\/\\/' // protocol
		+ '((\\w+\\.)+\\w+)' // domain name
		+ '(\\:\\d+)?' // port
		+ '(\\/\\S*)?$', 'i' ); // path
	return pattern.test( str );
};

const extractAndRenderLinks = ( content ) => {
	// Split content into potential URLs and text
	const urlRegex = /https?:\/\/[^\s]+/g;
	const parts = [];
	let lastIndex = 0;
	
	// Extract URLs and the text around them
	content.replace( urlRegex, ( match, offset ) => {
		// Push text before URL
		if ( offset > lastIndex ) {
			parts.push( content.slice( lastIndex, offset ) );
		}
		// Push URL
		parts.push( match );
		lastIndex = offset + match.length;
	} );
	
	// Push remaining text after the last URL
	if ( lastIndex < content.length ) {
		parts.push( content.slice( lastIndex ) );
	}
	
	// Render parts as text or links
	return parts.map( ( part, index ) => {
		if ( validURL( part ) ) {
			return (
				<PageLinkComponent
					key={index}
					href={part}
					target='_blank'
					sx={{
						'color'       : 'primary.main',
						':not(:hover)': { textDecoration: 'none' },
						'fontWeight'  : 500,
					}}>
					{part}
				</PageLinkComponent>
			);
		}
		return <span key={index}>{part}</span>;
	} );
};

export default function MessageComp( {
	room,
	message,
	sender,
	showName,
	createdAtTimestamp,
	updatedAtTimestamp,
	clientView,
}: {
	room: Room,
	message: Message,
	sender?: SenderType,
	showName?: boolean,
	createdAtTimestamp?: Date,
	updatedAtTimestamp?: Date,
	clientView?: boolean
} ) {
	
	return (
		<Stack sx={{ m: 0, ml: 1 }}>
			{showName && (
				<Stack direction='row' alignItems='center' spacing={1}>
					<Typography>
						{sender?.firstName || ''}
					</Typography>
					<Tooltip title={safeFormatInTimeZone( createdAtTimestamp, 'Pp' )}>
						<Typography style={{ fontSize: 12 }} sx={{ color: 'text.secondary' }}>
							{safeFormatInTimeZone( createdAtTimestamp, 'h:m a' )}
						</Typography>
					</Tooltip>
				</Stack>
			)}
			{!message.deleted && (
				message.from === 'AI'
					? <Markdown>{message.content}</Markdown>
					: (
						<Typography sx={{ whiteSpace: 'pre-line', color: 'text.primary' }}>
							{extractAndRenderLinks( message.content )}
						</Typography>
					)
			)}
			{message.deleted && (
				<Typography sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
					Message Deleted
				</Typography>
			)}
			{!message.deleted && Boolean( createdAtTimestamp ) && Boolean( updatedAtTimestamp ) && !safeDateFns.isEqual( createdAtTimestamp, updatedAtTimestamp ) && (
				<Typography
					component='span'
					style={{ fontSize: 11 }}
					sx={{ color: 'text.secondary' }}>
					(Edited)
				</Typography>
			)}
			{!message.deleted && message.attachmentUrls?.map( ( url ) => (
				<Box key={url} sx={{ mt: .5 }}>
					<Attachment src={url}/>
				</Box>
			) )}
		</Stack>
	);
}

