import {
	addDoc,
	collection,
	doc,
	DocumentData,
	getDocs,
	getFirestore,
	limit,
	onSnapshot,
	orderBy,
	query,
	serverTimestamp,
	startAfter,
	updateDoc,
	where,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';
import { RoomType } from '../types/chatRoom';
import { isProduction } from '../utils/config';
import app, { auth } from './client';

export const db = getFirestore( app );

export const ENV = isProduction ? 'prod' : 'dev';

type Options = {
	limit?: number,
	order?: 'asc' | 'desc'
};

export type RoomResponse = {
	room: Room | null,
	error: any
};

export type Room = {
	id: string,
	name: string,
	type: RoomType,
	company: string,
	staffs: string[],
	attendees: string[],
	client: string,
	commerceId: string,
	createdAt: Date,
	updatedAt: Date
};

export type Message = {
	id: string,
	uid: string,
	from: string,
	content: string,
	attachmentUrls: string[],
	createdAt: Date,
	updatedAt: Date,
	readBy: string[],
	deleted: boolean
};

export const useRooms = (
	companyId?: string | null,
	type?: RoomType,
	clientView?: boolean,
	options?: Options ): { rooms?: Room[], loading: boolean } => {
	let q = query( collection( db, 'envs', ENV, 'rooms' ) );
	if ( Boolean( type ) ) {
		q = query( q, where( 'type', '==', type ) );
	}
	if ( !clientView && Boolean( companyId ) ) {
		q = query( q, where( 'company', '==', companyId ) );
	}
	q = query(
		q,
		where( 'attendees', 'array-contains', auth.currentUser?.uid ),
		orderBy( 'createdAt', options?.order || 'desc' ),
		limit( options?.limit || 50 ),
	);
	
	const [ snapshot, loading, error ] = useCollection( q );
	if ( error ) throw error;
	const rooms = snapshot?.docs.map( ( doc ) => ( { id: doc.id, ...doc.data() as Omit<Room, 'id'> } ) );
	return { rooms, loading: loading };
	
};

export const useRoom = ( roomId: string | null ): RoomResponse => {
	const docRef: any = doc( db, 'envs', ENV, 'rooms', roomId || '' );
	const [ data, loading, error ] = useDocumentData<Omit<Room, 'id'>>( docRef );
	
	if ( !roomId ) {
		return { room: null, error: 'Room ID is null' };
	}
	if ( loading ) {
		return { room: null, error: 'loading' };
	}
	if ( !data ) {
		return { room: null, error: 'No data available' };
	}
	return { room: { id: roomId, ...data }, error: error };
};
export const useRoomMessages = ( roomId: string, options?: Options ): Message[] | null => {
	const q = query(
		collection( db, 'envs', ENV, 'rooms', roomId, 'messages' ),
		orderBy( 'createdAt', options?.order || 'desc' ),
		limit( options?.limit || 50 ),
	);
	const [ snapshot, loading, error ] = useCollection( q );
	if ( error ) throw error;
	if ( loading || !snapshot ) return null;
	const messages = snapshot?.docs.map( ( doc ) => ( { id: doc.id, ...doc.data() as Omit<Message, 'id'> } ) );
	return messages || [];
};

export const useInfiniteLoadMessages = ( roomId: string, options?: Options ) => {
	const [ messages, setMessages ] = useState<Message[]>( [] );
	const [ lastDoc, setLastDoc ] = useState<DocumentData | null>( null );
	const [ hasMore, setHasMore ] = useState( true );
	const BATCH_SIZE = options?.limit || 50;
	const loading = false;
	
	const getInitialQuery = () => query(
		collection( db, 'envs', ENV, 'rooms', roomId, 'messages' ),
		orderBy( 'createdAt', options?.order || 'desc' ),
		limit( options?.limit || BATCH_SIZE ),
	);
	
	const getNextQuery = () => lastDoc ? query(
		collection( db, 'envs', ENV, 'rooms', roomId, 'messages' ),
		orderBy( 'createdAt', options?.order || 'desc' ),
		startAfter( lastDoc ),
		limit( options?.limit || BATCH_SIZE ),
	) : getInitialQuery();
	
	useEffect( () => {
		if ( !roomId ) return;
		
		const initialQuery = getInitialQuery();
		
		const unsubscribe = onSnapshot( initialQuery, ( documentSnapshots ) => {
			const loadedMessages = documentSnapshots.docs.map( ( doc ) => ( { id: doc.id, ...doc.data() as Omit<Message, 'id'> } ) );
			setMessages( loadedMessages.reverse() );
			setLastDoc( documentSnapshots.docs[ documentSnapshots.docs.length - 1 ] );
			setHasMore( documentSnapshots.docs.length >= BATCH_SIZE );
		}, ( error ) => {
			console.error( 'Error fetching messages:', error );
		} );
		
		return () => unsubscribe();
	}, [ roomId, BATCH_SIZE ] );
	
	if ( !roomId ) return { messages: [], fetchNextPage: () => {}, hasMore: false, loading: false };
	
	const fetchNextPage = async () => {
		if ( !hasMore || !lastDoc ) return;
		
		const nextQuery = lastDoc ? getNextQuery() : getInitialQuery();
		
		if ( nextQuery ) {
			try {
				const documentSnapshots = await getDocs( nextQuery );
				const newMessages = documentSnapshots.docs.map( ( doc ) => ( { id: doc.id, ...doc.data() as Omit<Message, 'id'> } ) );
				setMessages( ( prevMessages ) => {
					const existingIds = new Set( prevMessages.map( ( msg ) => msg.id ) );
					const uniqueNewMessages = newMessages.filter( ( msg ) => !existingIds.has( msg.id ) );
					return [ ...uniqueNewMessages.reverse(), ...prevMessages ];
				} );
				
				setLastDoc( documentSnapshots.docs[ documentSnapshots.docs.length - 1 ] );
				setHasMore( documentSnapshots.docs.length >= BATCH_SIZE );
			} catch ( error ) {
				console.error( 'Error fetching next page of messages:', error );
			}
		}
	};
	return { messages, fetchNextPage, hasMore, loading };
};

export const usePostMessage = ( roomId: string ) => {
	const [ loading, setLoading ] = useState( false );
	
	const postMessage = async ( payload: Omit<Message, 'id' | 'deleted' | 'createdAt' | 'updatedAt' | 'readBy'> ) => {
		setLoading( true );
		const docRef = collection( db, 'envs', ENV, 'rooms', roomId, 'messages' );
		const currentTimestamp = serverTimestamp();
		const message = await addDoc( docRef, {
			...payload,
			readBy   : [ payload.uid ],
			createdAt: currentTimestamp,
			updatedAt: currentTimestamp,
		} );
		setLoading( false );
		return message;
	};
	
	return { postMessage, loading };
};

export const useUpdateMessage = ( roomId: string ) => {
	const [ loading, setLoading ] = useState( false );
	
	const updateMessage = async ( messageId: string,
		updatedData: Omit<Message, 'id' | 'deleted' | 'readBy' | 'createdAt' | 'updatedAt' | 'attachmentUrls' | 'reference'> ) => {
		setLoading( true );
		const messageRef = doc( db, 'envs', ENV, 'rooms', roomId, 'messages', messageId );
		const currentTimestamp = serverTimestamp();
		await updateDoc( messageRef, {
			...updatedData,
			updatedAt: currentTimestamp,
		} );
		
		setLoading( false );
	};
	
	return { updateMessage, loading };
};

