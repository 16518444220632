import { Avatar, Badge } from '@mui/material';

export default function BadgedAvatar( {
	user,
	invisibleBadge,
}: {
	user: {
		firstName?: string,
		lastName?: string,
		image?: string,
		contact?: string,
		logo?: string
	},
	invisibleBadge?: boolean
} ) {
	
	user = user.contact ? {
		// its a client
		...user,
		firstName: user.contact.split( ' ' )[ 0 ][ 0 ],
		lastName : user.contact.split( ' ' )[ 1 ][ 0 ],
		image    : user.logo,
	} : user;
	
	return (
		<Badge
			overlap='circular'
			invisible={invisibleBadge}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			badgeContent={undefined}>
			<Avatar
				variant='rounded'
				src={user.image}
				alt={user.firstName}
				sx={{ width: 40, height: 40, borderRadius: 2 }}>
				{user.firstName?.[ 0 ] || '' + user.lastName?.[ 0 ] || ''}
			</Avatar>
		</Badge>
	);
}
