import { gql } from '@apollo/client';

export const staffsReadQueryForEmails = gql`
	query StaffsReadForEmails_4c60($options: FilterOptions, $staffQuery: [StaffQueryInput!]) {
		staffsReadForEmails(options: $options, staffQuery: $staffQuery) {
			items {
				id
				email
				user {
					firstName
					lastName
					image
				}
				company {
					id
					name
					email
					logo
				}
			}
		}
	}
`;

export const staffsReadQuery = gql`
	query StaffsRead_7bee($options: FilterOptions) {
		staffsRead(options: $options) {
			items {
				id
				email
				user {
					firstName
					lastName
					image
				}
				company {
					id
					name
					email
					logo
				}
			}
		}
	}
`;

export const clientsReadQuery = gql`
	query ClientsRead_8981($options: FilterOptions) {
		clientsRead(options: $options) {
			items {
				id
				email
				name
				contact
				logo
			}
		}
	}
`;

export const clientsReadQueryForEmails = gql`
	query ClientsReadForEmails_e35a($options: FilterOptions, $emails: [String!]) {
		clientsReadForEmails(options: $options, emails: $emails) {
			items {
				id
				email
				name
				contact
				logo
			}
		}
	}
`;
