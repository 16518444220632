import { Room } from '@/firebase/firestore';
import useUserInfo from '@/providers/auth/useUserInfo';
import { clientsMapAtom, staffsMapAtom } from '@/utils/atoms';
import { Chip, Paper, Stack, Typography } from '@mui/material';
import { useAtom } from 'jotai/index';
import BadgedAvatar from './avatar';

export default function ChatHeader( { room, clientView }: { room: Room, clientView: boolean } ) {
	const { user } = useUserInfo();
	const [ clientsAtom ] = useAtom( clientsMapAtom );
	const [ staffsAtom ] = useAtom( staffsMapAtom );
	
	const client = room?.client && clientsAtom[ room.client ];
	const staffs = room?.staffs ? room.staffs.map( ( x ) => staffsAtom[ x ] ).filter( Boolean ) : [];
	
	if ( clientView ) return (
		<Paper
			sx={{
				p      : 2,
				m      : 2,
				bgcolor: 'background.default',
			}}>
			<Stack>
				<BadgedAvatar key={user.id} user={user}/>
				<Typography variant='h6'>
					This is the beginning of your chat history.
				</Typography>
				<Typography sx={{ color: 'text.secondary', mt: 0 }}>
					{staffs?.[ 0 ]?.company?.name}
				</Typography>
				<Typography sx={{ color: 'text.secondary', mt: 0 }}>
					{staffs?.[ 0 ]?.company?.email}
				</Typography>
			</Stack>
		</Paper>
	);
	
	return (
		<Paper
			sx={{
				p      : 2,
				m      : 2,
				bgcolor: 'background.default',
			}}>
			<Stack>
				<Stack>
					<Typography variant='h6'>
						This is the beginning of your chat history.
					</Typography>
					<Typography sx={{ color: 'text.secondary' }}>
						The Staff in this room are:
					</Typography>
				</Stack>
				<Stack direction='row' alignItems='center' sx={{ flexWrap: 'wrap' }}>
					{staffs?.map( ( staff, index ) => (
						<Chip
							key={index}
							variant='alpha'
							color='primary'
							sx={{ mt: 1, mr: 1 }}
							label={staff.firstName}
						/>
					) )}
				</Stack>
				{client && (
					<Stack direction='row' spacing={1} alignItems='center' sx={{ mt: 1 }}>
						<Typography sx={{ color: 'text.secondary' }}>
							Client:
						</Typography>
						<Chip
							variant='alpha'
							color='success'
							label={client.contact || client.email || client.name}
						/>
					</Stack>
				)}
			</Stack>
		</Paper>
	);
}
