import Loading from '@/components/loading';
import { CommentsDisabled as NoChatIcon } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { useRoom } from '../../../../firebase/firestore';
import BottomBar from './bottomBar';
import ChatMessages from './messages';
import ChatTopBar from './topBar';

export default function MessagesSection( {
	clientView = false,
	roomId,
}: {
	clientView?: boolean,
	roomId: string
} ) {
	const { room, error } = useRoom( roomId );
	
	if ( !room && error ) {
		return (
			<Box className='center'>
				<Box sx={{ textAlign: 'center' }}>
					<NoChatIcon fontSize='large'/>
					<Typography>Room Not Found</Typography>
				</Box>
			</Box>
		);
		
	}
	
	if ( !room ) return <Loading/>;
	
	return (
		<Fragment>
			<ChatTopBar
				room={room}
				clientView={clientView}
			/>
			<ChatMessages
				room={room}
				clientView={clientView}
			/>
			<BottomBar
				room={room}
				clientView={clientView}
			/>
		</Fragment>
	);
}

